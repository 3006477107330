<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <div>
          <b-card>
            <b-tabs
              content-class="mt-2"
              justified
            >
              <b-tab
                title="Incubator Feedbacks"
                active
              >
                <manage-startups-feedbacks />
              </b-tab>
              <b-tab title="Mentor Feedbacks">
                <manage-mentors-feedbacks />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BTabs, BCard, BTab,
} from 'bootstrap-vue'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'
// import { FormWizard, TabContent } from 'vue-form-wizard'
// import StartupRequests from '@/views/incubator-portal/startups/pages/startup-manager/requests/Startup.vue'
// import MentorRequests from '@/views/incubator-portal/startups/pages/startup-manager/requests/Mentor.vue'
// import PartnerRequests from '@/views/incubator-portal/startups/pages/startup-manager/requests/Partner.vue'
// import ManageFeedbacks from '@/views/incubator-portal/ManageFeedbacks.vue'
import ManageMentorsFeedbacks from '@/views/startup-portal/pages/feedbacks/ManageMentorsFeedbacks.vue'
import ManageStartupsFeedbacks from '@/views/startup-portal/pages/feedbacks/ManageStartupsFeedbacks.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    // Requests,
    // Emeetings,
    // Emails,
    // ValidationProvider,
    // ValidationObserver,
    // FormWizard,
    // TabContent,
    // StartupRequests,
    // MentorRequests,
    // PartnerRequests,
    BRow,
    BCol,
    BTabs,
    BTab,
    // ManageFeedbacks,
    ManageMentorsFeedbacks,
    ManageStartupsFeedbacks,
    BCard,
    // BFormGroup,
    // BFormInput,
    // BFormTextarea,
    // vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      mutationLoading: false,
      incubator: {
        title: null,
        designation: null,
        description: null,
        official_email: null,
        official_phone: null,
        url: null,
        industry: null,
        startup_stage: null,
        city: null,
        state: null,
      },
      industries: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      required,
      email,
    }
  },
  computed: {
    orgAssocId() {
      return getUserData().associatedOrgDetails.filter(org => org.role === 'superadmin')[0].id
    },
  },
  methods: {
    formSubmitted() {
      this.addIncubator()
      this.mutationLoading = true
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.incubatorBasicInfoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    mutationObject() {
      const incubatorDetails = { ...this.incubator }
      delete incubatorDetails.assoc_id
      delete incubatorDetails.designation
      const object = {
        users_organizationtable: {
          data: {
            ...incubatorDetails,
          },
          on_conflict: {
            constraint: 'users_organizationtable_pkey',
            update_columns: ['title',
              'description',
              'official_email',
              'official_phone',
              'url',
              'industry',
              'startup_stage',
              'city',
              'state'],
          },
        },
        designation: this.incubator.designation,
        user_id: getUserData().id,
      }
      if (this.incubator.assoc_id) object.id = this.incubator.assoc_id
      delete object.assoc_id
      return object
    },
    addIncubator() {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation update_incubator_details($object: users_associationtable_insert_input! ) {
          insert_users_associationtable_one(on_conflict: {update_columns: designation, constraint: users_associationtable_pkey}, object: $object) {
            id
          }
        }`,
        variables: {
          object: this.mutationObject(),
        },
        update: (store, { data: { insert_users_associationtable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_users_associationtable_one.id ? 'Incubator Updated' : 'There was an Error',
              icon: 'EditIcon',
              variant: insert_users_associationtable_one.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.incubator.refetch()
          this.mutationLoading = false
          this.incubator = {
            title: null,
            designation: null,
            description: null,
            official_email: null,
            official_phone: null,
            url: null,
            industry: null,
            startup_stage: null,
            city: null,
            state: null,
          }
        },
      })
      return this.error
    },
  },
  apollo: {
    incubator: {
      query() {
        return gql`{
          users_associationtable_by_pk(id: ${this.orgAssocId}) {
            id
            designation
            users_organizationtable {
              id
              title
              description
              official_email
              official_phone
              url
              industry
              startup_stage
              city
              state
            }
          }
        }`
      },
      update: data => ({
        assoc_id: data.users_associationtable_by_pk.id,
        designation: data.users_associationtable_by_pk.designation,
        ...data.users_associationtable_by_pk.users_organizationtable,
      }),
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
